.webkit-box {
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

/* 375px */
.delay-clamp-to-8 {
  animation: clamp-to-8 0s linear 1s forwards;
}

/* 768px, 1024px, 1440px, 1920px */
.delay-clamp-to-5 {
  animation: clamp-to-5 0s linear 1s forwards;
}

.line-clamp-none {
  -webkit-line-clamp: none;
}

.line-space::after {
  content: '\a';
  display: block;
  white-space: pre-line;
}

.line-space-sm::after {
  height: 1rem;
}

.line-space-lg::after {
  /* height: 1.625rem; */
  height: 1.5625rem;
}

.line-space-xl::after {
  height: 2rem;
}

@keyframes clamp-to-8 {
  from {
    -webkit-line-clamp: none;
  }
  to {
    -webkit-line-clamp: 8;
  }
}

@keyframes clamp-to-5 {
  from {
    -webkit-line-clamp: none;
  }
  to {
    -webkit-line-clamp: 5;
  }
}
