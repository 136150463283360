body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/rubik/Rubik-Medium.ttf') format('truetype');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/rubik/Rubik-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TeX Gyre Pagella';
  src: url('./fonts/texgyrepagella/texgyrepagella-regular.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TeX Gyre Pagella';
  src: url('./fonts/texgyrepagella/texgyrepagella-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TeX Gyre Pagella';
  src: url('./fonts/texgyrepagella/texgyrepagella-italic.otf')
    format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'TeX Gyre Pagella';
  src: url('./fonts/texgyrepagella/texgyrepagella-bolditalic.otf')
    format('opentype');
  font-weight: bold;
  font-style: italic;
}

@import url('https://p.typekit.net/p.css?s=1&k=zlh1ugy&ht=tk&f=36538&a=62460209&app=typekit&e=css');

@font-face {
  font-family: 'classica-pro';
  src: url('https://use.typekit.net/af/c8223b/00000000000000007735a8d2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/c8223b/00000000000000007735a8d2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/c8223b/00000000000000007735a8d2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
